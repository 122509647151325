<script>
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';
    export let dontknow = {};
    export let tkm_min, tkm_max, tkm_default;
    let oldValue = '';

    let info = false;

    $: if (data.value && tkm_min && tkm_max) {
        data.setError();
    }

    function validate(value) {
        if (! dontknow.value) {
            if (isNaN(value)) {
                value = parseFloat(value.replace(',', '.'));
            }

            if (! value) {
                return 'Valeur incorrecte';
            }

            if (tkm_min && tkm_max) {
                if (value < tkm_min || value > tkm_max) {
                    return `Pour la catégorie de véhicule et les options que vous avez indiquées,\
                            le taux kilométrique de référence par km pour 600 km doit être compris\
                            entre ${tkm_min} et ${tkm_max}`;
                }
            }
        }

        return '';
    }

    if (dontknow.value) {
        oldValue = data.value;
        data.value = '';
    }

    function handle_TKMmin_dontknow() {
        if (dontknow.value) {
            oldValue = data.value;
            data.value = '';
        } else {
            data.value = oldValue;
        }
        data.setError();
    }
</script>

<style>
    .oct_form_field__taux__km__description {
        color: #439fcc;
        font-size: 13px;
        margin-top: 5px;
        font-weight: normal;
    }

    .oct_form_field__km__none {
        margin-right: 5px;
    }

    .oct-label-tkm-explication {
        font-size: 12px;
        margin: 0;
    }
</style>

<div class="taux-kilometriques">
    <Input {prefix} {name} mask="number" {validate} bind:data display_error={false}
           on:input={() => {dontknow.value = false}} />

    <div class="taux-wrapper">
        <span class="oct-taux-kilometrique__unite">
            {#if dontknow.value}<span>{tkm_default}</span>{/if} €/km
        </span>
        <img src="/static/public/img/puce_info.png" class="oct-puce-taux-reference" alt="puce info"
            on:click={() => {info = ! info}}>
    </div>
</div>

<label class="radio">
    <input type="checkbox" name="TKMmin_dontknow" bind:checked={dontknow.value}
           class="oct_form_field__km__none" on:change={handle_TKMmin_dontknow}>
    je ne sais pas
</label>

{#if data.error}
    <br><span class="oct-info-error">{data.error}</span>
{/if}
{#if dontknow.value}
    <p class="oct-label-tkm-explication">
        TKM 600 km proposé par défaut : {tkm_default} €/km
    </p>
{/if}
{#if info}
    <p class="oct_form_field__taux__km__description">
        km approche + km en charge + km retour
        <br/>
        Le taux de référence par km que vous choisissez est indicatif pour un
        trajet de 600 km et doit tenir compte des caractéristiques de votre
        entreprise et de votre véhicule (conseiller à la sécurité ADR,
        catégorie de véhicule, frigorifique, hayon élévateur...).
        <br/>
        Vous pourrez à tout moment modifier votre taux kilométrique 600km sur
        votre espace logué.
    </p>
{/if}
